import { wsService } from "./wsService";

function login(username, password) {
  const param = {
    username: username,
    password: password,
  };
  return wsService.make_post_request("auth/login", param);
}

function isAuthenticated() {
  return localStorage.getItem("token") != undefined && localStorage.getItem("expire_in") != undefined && Math.floor(Date.now() / 1000) < localStorage.getItem("expire_in");
}

function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("expire_in");
}

export const authService = {
  login,
  isAuthenticated,
  logOut,
};
