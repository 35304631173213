<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: 'MARITIMAS | Ordenes Comerciales' }]"></Title>

    <Modal
      :parent="parent"
      @updateDialog="updateDialog"
      :dialog="dialog"
      :item="mainItem"
    />

    <DialogConfirmVue
      :parent="parent"
      @updateDialog="closeReadDialog"
      :dialog="dialog_read"
      :item="mainItem"
      text="¿Desea marcar la notificacion como leida?"
      title="Leer Notificacion"
      @action="readItem"
    />

    <DialogConfirmVue
      :parent="parent"
      @updateDialog="dialog_read_massive = false"
      :dialog="dialog_read_massive"      
      text="¿Desea marcar las notificaciones seleccionadas como leidas?"
      title="Leer Notificaciones"
      @action="massiveRead"
    />

    <v-container fluid>
      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3">
                  <v-text-field
                    label="Desde*"
                    data-vv-as="desde"
                    name="desde_selected"
                    v-model="desde_selected"
                    type="date"                    
                    autocomplete="off" 
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("desde_selected") }}</span>                  
                </div>

                <div class="col-md-3">
                  <v-text-field
                    label="Hasta*"
                    data-vv-as="hasta"
                    name="hasta_selected"
                    v-model="hasta_selected"
                    type="date"
                    autocomplete="off" 
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("hasta_selected") }}</span>                  
                </div>                
                
                <div class="col-md-3">
                  <v-text-field 
                    v-model="title_selected" a
                    utocomplete="off" data-vv-as="titulo" name="title_selected" label="Titulo" 
                    class="">
                    </v-text-field>
                </div>     

                <div class="col-md-2">
                  <v-select
                    v-model="readed_selected"
                    :items="[{ text: 'Si', value: '1' }, { text: 'No', value: '0' }]"
                    label="Leido"
                    autocomplete="off"
                    data-vv-as="leido"
                    name="readed_selected"
                  ></v-select>
                </div>                            

                <div class="col-md-1" style="display: flex;justify-content: end;">
                  <!--<v-btn color="error" class="mr-2" @click="export_filter">Exportar</v-btn>-->
                  <v-btn color="info" class="mt-2" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
         <div v-if="selected.length != 0" class="col-md-12" style="background: #f4f1f1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" class="cursor mb-1 mt-1" @click="dialog_read_massive = true">mail</v-icon>
            </template>
            <span>Marcar como leido</span>
          </v-tooltip>
        </div>
        <v-data-table 
          :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..." hide-default-footer disable-pagination
          v-model="selected"
          show-select
          :single-select="false"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr :class="!item.readed?'unread':''">
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" :disabled="item.readed" />
              </td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.message.length > 50 ? item.message_text.substring(0, 70) + '...' : item.message_text }}</td>
              <td>{{ item.readed?'Si':'No' }}</td>
              <td class="justify-content-center px-0">                

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!item.readed">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="openReadDialog(item)">
                      mail
                    </v-icon>
                  </template>
                  <span>Marcar como leido</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import DialogConfirmVue from '../../components/DialogConfirm.vue';

export default {
  components: { Title, Modal, DialogConfirmVue },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      selected: [],
      parent: this,
      service: generalService,
      page_title: this.$t("Notificaciones"),
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      title_selected: "",
      readed_selected: "",
      dialog_read: false,
      dialog_read_massive: false,
      mainItem: {
        id: "",
        creation_date: "",
        title: "",
        message: "",
        readed: "",
      },
      defaultItem: null,
      headers: [
        { text: "Fecha", value: "creation_date" },
        {
          text: "Titulo",
          align: "left",
          sortable: true,
          value: "title",
        },
        { text: "Mensaje", value: "message" },
        { text: "Leido", value: "readed" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],      
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.service.setSection(Constant.SECTION_NOTIFICATIONS);
    this.loadList(this.service);
  },
  methods: {
    filter() {
      this.service.setSection(Constant.SECTION_NOTIFICATIONS);
      this.loadList(this.service, this.getFilterParams());
    },
    getFilterParams() {
      const param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        title: this.title_selected,
        readed: this.readed_selected,
      };
      return param;
    },
    openReadDialog(item) {
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.dialog_read = true;
    },
    closeReadDialog() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog_read = false;
    },
    readItem() {
      this.service.setSection(Constant.SECTION_NOTIFICATIONS);
      this.service.update({ id: this.mainItem.id, readed: 1 }).then((response) => {
        if(response.status === true) {
          this.$store.commit("setAlerts", {
              alerts: this.$store.state.alerts - 1 
          });
          this.closeReadDialog();
          this.loadList(this.service); 
        } else {
          this.$toast.error(response.msg);
        }               
      });
    },
    massiveRead(){
      let notificationsIds = this.selected.map((a) => a.id);
      
      this.service.custom_post({ ids: notificationsIds },Constant.SECTION_NOTIFICATIONS+"/massive_read").then((response) => {
        if(response.status === true) {
          this.$store.commit("setAlerts", {
              alerts: this.$store.state.alerts - notificationsIds.length 
          });
          this.selected = [];
          this.dialog_read_massive = false;
          this.loadList(this.service); 
        } else {
          this.$toast.error(response.msg);
        }               
      });
    }
  },
};
</script>
