<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', $event.target.value)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="username" label="Username*" name="username" v-model="item.username" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("username") }}</span>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  label="Password*"
                  data-vv-as="password"
                  name="password"
                  v-validate="(item.id > 0)?'':'required'"
                  v-model="item.password"
                  :disabled="isViewModal()"
                />
                <span class="red--text">{{ errors.first("password") }}</span>

                <v-text-field data-vv-as="email" label="Email*" name="email" v-model="item.email" v-validate="'required'" :disabled="isViewModal()"></v-text-field>

                <v-combobox
                  v-model="item.role"
                  data-vv-as="rol"
                  name="rol"
                  item-text="name"
                  item-value="id"
                  :items="roles"
                  label="Rol*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("rol") }}</span>

                <div class="row">
                  <div class="col-md-12">
                    <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enable"></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { usersService } from "@/libs/ws/usersService";
import { rolService } from "@/libs/ws/rolService";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  ModalMixin,
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadRoles() {
      rolService
        .getEnable()
        .then((response) => {
          this.roles = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(e) {
      if(this.loading)
        return;
      this.setLoading(true);
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El usuario se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> this.setLoading(false));
          } else {            
            usersService
              .create(this.item)
              .then((response) => {                
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El usuario se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else{
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> this.setLoading(false));
          }
        }
      });
    },
  },
};
</script>
