<template>
  <div class="row">
    <div class="col-md-12">
      <v-toolbar color="green" dark dense class="pl-5 elevation-0">
        <v-tooltip bottom :open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click="handleSidebar"></v-app-bar-nav-icon>
          </template>
          <span>Menú principal</span>
        </v-tooltip>

        <div class="position-absolute d-flex align-items-center" style="right:5px">
          <!--
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="#4caf50" small v-bind="attrs" v-on="on" elevation="0"> {{ $t("Idioma_label") }} {{ $i18n.locale }} </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in idiomas" :key="index">
                <v-list-item-title class="cursor" @click="langSelector(item.code)">{{ item.name + " - " + item.code.toUpperCase() + "" }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          -->
          <p class="mb-0 mr-2">{{ getUsername() }}</p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on:click="logout" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-export</v-icon>
              </v-btn>
            </template>
            <span>Cerrar sesión</span>
          </v-tooltip>
        </div>
      </v-toolbar>
    </div>
  </div>
</template>
<script>
import { authService } from "@/libs/ws/authService";
export default {
  name: "App",
  data() {
    return {
      idiomas: [{ id: 0, name: this.$t("Lang_ES"), code: "es" }],
    };
  },
  mounted() {
    /* this.$i18n.locale = "en"; */
  },
  methods: {
    handleSidebar() {
      if (window.innerWidth >= 1264) {
        this.$store.commit("setOpenSidebar", {
          open: !this.$store.state.sidebar.open,
        });
      } else {
        this.$store.commit("setOpenSidebar", {
          drawer: !this.$store.state.sidebar.drawer,
          open: true,
        });
      }
    },
    getUsername() {
      return localStorage.getItem("username");
    },
    logout() {
      authService.logOut();
      this.$router.push("/login");
    },
    langSelector(name) {
      this.$i18n.locale = name;
      localStorage.setItem("lang", name);
      location.reload();
    },
  },
};
</script>
