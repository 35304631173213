import { wsService } from "./wsService";

const section = "orders";

function filter(param) {
  return wsService.make_post_request(section + "/filter", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function print(order_id, type) {
  return wsService.make_get_request("print/order/" + order_id + "/" + type, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function report(params) {
  return wsService.make_post_request(section + "/report", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function history_autorizaciones(param) {
  return wsService.make_post_request(section + "/historial-autorizaciones", param);
}

export const orderService = {
  section,
  filter,
  remove,
  create,
  update,
  print,
  export_filter,
  report,
  history_autorizaciones,
};
