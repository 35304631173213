import { wsService } from "./wsService";

var section = "";

function filter(param = {}, path = section) {  
  return wsService.make_post_request(path + "/list", param);
}

function create(param = {}, path = section) {
  return wsService.make_post_request(path + "/create", param);
}

function update(param = {}, path = section) {
  return wsService.make_put_request(path + "/update", param);
}

function remove(param = {}, path = section) {
  return wsService.make_delete_request(path + "/delete", param);
}

function custom_post(param = {}, path = section) {
  return wsService.make_post_request(path , param);
}

function setSection(s) {
  section = s;
}

export const generalService = { 
  section,
  setSection, 
  filter,
  remove,
  create,
  update,
  custom_post,
};